import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import firebase from "firebase/app";
import "firebase/auth";

let baseURL = "https://sgapi.shopsogood.live";
const devURL = "https://2c5096dd97e2.ngrok.io";

if (process.env.NODE_ENV === "development") {
  baseURL = devURL;
}

// baseURL = "https://devo-api.shopsogood.live";

export async function unauthedHttp<T>(request: AxiosRequestConfig): Promise<T> {
 
  const response: AxiosResponse = await axios(request);
  return response.data as T;
}

export async function unauthedGet<T>(url: string): Promise<T> {
  return await unauthedHttp<T>({
    method: "GET",
    headers: { "Content-Type": "application/json" },
    url: baseURL + url,
  });
}

export async function http<T>(request: AxiosRequestConfig): Promise<T> {
  const firebaseId = await firebase.auth().currentUser?.getIdToken();
  if (request.headers && firebaseId) {
    // request.headers.Authorization = `Bearer ${token}`;
    request.headers["x-api-token"] = firebaseId;
  }
  const response: AxiosResponse = await axios(request);
  return response.data as T;
}

/** The HTTP GET method requests a representation of the specified resource. */
export async function get<T>(url: string): Promise<T> {
  return await http<T>({
    method: "GET",
    headers: { "Content-Type": "application/json" },
    url: baseURL + url,
  });
}

/** Send data in the body of the HTTP Request */
export async function post<T>(url: string, body: any): Promise<T> {
  return await http<T>({
    data: body,
    headers: { "Content-Type": "application/json" },
    method: "POST",
    url: baseURL + url,
  });
}


export async function put<T>(url: string, body: any): Promise<T> {
  return await http<T>({
    data: body,
    headers: { "Content-Type": "application/json" },
    method: "PUT",
    url: baseURL + url,
  });
}

/**
 * The HTTP PUT request method creates a new resource or replaces
 * a representation of the target resource with the request payload.
 */
// export async function put<T>(url: string, body: any): Promise<T> {
//   return await http<T>({
//     data: body,
//     headers: { "Content-Type": "application/json" },
//     method: "PUT",
//     url: url,
//   });
// }

// /** The HTTP DELETE request method deletes the specified resource. */
// export async function remove<T>(url: string): Promise<T> {
//   return await http<T>({
//     method: "DELETE",
//     url: url,
//   });
// }

