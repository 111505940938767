/* eslint-disable camelcase */
import { Box } from "@chakra-ui/react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { useRef } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import DownloadApp from "../AppUpsellModal.tsx/DownloadApp";
import { SheetContent } from "../../molecules/SheetContent";
import "firebase/auth";

type AppUpsellModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  justCheckedOut: boolean;
};

const AppUpsellModal = (props: AppUpsellModalProps) => {
  const carouselRef = useRef<Carousel>(null);

  return (
    <BottomSheet
      open={props.isOpen}
      onDismiss={props.handleClose}
      expandOnContentDrag
      // defaultSnap={({ snapPoints, lastSnap }) =>
      //   lastSnap ?? Math.min(...snapPoints)
      // }
      // snapPoints={({ maxHeight }) => [
      //   maxHeight - maxHeight / 5,
      //   maxHeight * 0.6,
      // ]}
    >
      {/* <Carousel
        ref={carouselRef}
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        swipeable={false}
      >
        [ */}
      <SheetContent>
        <Box width={"100%"} px={5} py={5}>
          <DownloadApp justCheckedOut={props.justCheckedOut}/>
        </Box>
      </SheetContent>
      {/* ]
      </Carousel> */}
    </BottomSheet>
  );
};

export default AppUpsellModal;
