/* eslint-disable @typescript-eslint/ban-types */
import { get, post } from ".";
import { IShop, IShow, IUser } from "../types";
import {
  failureServiceResponse,
  IServiceResponse,
} from "../types/IServiceResponse";

export const ShowGateway = {
  fetchShows: async (): Promise<IServiceResponse<IShow[]>> => {
    try {
      const res = await get<IServiceResponse<IShow[]>>("/show/fetch");
      return res;
    } catch {
      return failureServiceResponse("Could not fetch shows");
    }
  },

  createShow: async (
    name: string,
    photoURL: string,
    videoURL: string | undefined,
    date: Date,
    isPrivate: boolean
  ): Promise<
    IServiceResponse<{ show: IShow; agoraChannel: string; token: string }>
  > => {
    try {
      const res = await post<
        IServiceResponse<{ show: IShow; agoraChannel: string; token: string }>
      >("/show/create", { name, photoURL, videoURL, date, isPrivate });
      return res;
    } catch {
      return failureServiceResponse("Could not create show");
    }
  },

  requestAgoraToken: async (
    showId: string
  ): Promise<IServiceResponse<{ token: string }>> => {
    try {
      const res = await get<IServiceResponse<{ token: string, isHost: false }>>(
        `/show/${showId}/token`,
      );
      return res;
    } catch (err) {
      return failureServiceResponse("Could not complete token request");
    }
  },

  joinFirestoreShow: async (
    agoraChannel: string,
    uid: string, 
  ): Promise<IServiceResponse<{ isHost: boolean }>> => {
    try {
      const res = await post<IServiceResponse<{ isHost: boolean }>>(
        "/show/join_firestore",
        { 
          agoraChannel,
          uid, 
          asAudience: true, 
        }
      );
      return res;
    } catch (err) {
      return failureServiceResponse("Could not join show");
    }
  },

  leaveFirestoreShow: async (
    agoraChannel: string,
    uid: string | undefined
  ): Promise<IServiceResponse<{}>> => {
    try {
      const res = await post<IServiceResponse<{}>>("/show/leave_firestore", {
        agoraChannel,
        uid,
        asAudience: true,
      });
      return res;
    } catch (err) {
      return failureServiceResponse("Could not leave show");
    }
  },

  addComment: async (
    agoraChannel: string,
    body: string
  ): Promise<IServiceResponse<{}>> => {
    try {
      const res = await post<IServiceResponse<{}>>("/show/add_comment", {
        agoraChannel,
        body,
      });
      return res;
    } catch (err) {
      return failureServiceResponse("Could not add comment");
    }
  },

  refreshShow: async (agoraChannel: string): Promise<IServiceResponse<{}>> => {
    try {
      const res = await post<IServiceResponse<{}>>("/show/refresh", {
        agoraChannel,
      });
      return res;
    } catch (err) {
      return failureServiceResponse("Could not add comment");
    }
  },

  getUser: async (id: number): Promise<IServiceResponse<IUser>> => {
    try {
      const res = await post<IServiceResponse<IUser>>("/show/get_user", {
        id,
      });
      return res;
    } catch (err) {
      return failureServiceResponse("Could not get user");
    }
  },

  getShop: async (id: string): Promise<IServiceResponse<IShop>> => {
    try {
      const res = await get<IServiceResponse<IShop>>(`/shop/${id}`);
      return res;
    } catch (err) {
      return failureServiceResponse("Could not get SHop");
    }
  },

  getShowById: async (id:string): Promise<IServiceResponse<IShow>> => {
    try {
      const res = await get<IServiceResponse<IShow>>(`/show/${id}`);
      return res;
    } catch (err) {
      return failureServiceResponse("Could not get show");
    }
  },
};
