import { ShippingForm } from "./ShippingForm";
import {
  VStack,
  Spacer,
  HStack,
  Text,
  Image, 
  Box,
} from "@chakra-ui/react";
import Numeral from "numeral";
import React, { useState } from "react";
import { IProduct } from "../../../types";
import CheckoutForm from "./CheckoutForm";
import { IPurchaseDetails } from "../../../types/IPurchase";
import { StripeGateway } from "../../../api/StripeGateway";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { validateServiceResponse } from "../../../utils";

type ProductOrderSummaryProps = {
  product: IProduct;
  quantity: number;
  purchase: IPurchaseDetails;
  stripePurchaseId: string | undefined;
  snapToPrev: () => void;
  snapToNext: () => void;
  onPaymentComplete: () => void;
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  addressLine1: string;
  setAddressLine1: React.Dispatch<React.SetStateAction<string>>;
  addressLine2: string;
  setAddressLine2: React.Dispatch<React.SetStateAction<string>>;
  city: string;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  state: string;
  setUSState: React.Dispatch<React.SetStateAction<string>>;
  zipCode: string;
  setZipCode: React.Dispatch<React.SetStateAction<string>>;
  email: string | undefined;
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
  error: string | undefined;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
  clientSecret: string | undefined;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

function ProductOrderSummary(props: ProductOrderSummaryProps) {

  const price = props.product.discount * props.quantity;

  const [showAddress, setAddressShown] = useState(true);
  const [showPayment, setPaymentShown] = useState(false);
 
  const closeShipping = () => {
    setAddressShown(false);
    props.setRefresh((r) => !r);
  };


  const expandShipping = () => {
    setAddressShown(true);
    setPaymentShown(false);
    props.setRefresh((r) => !r);
  };

  const toggleExpandShipping = showAddress ? closeShipping : expandShipping;


  const handleSubmitAddress = () => {
    // VALIDATE ADDRESSS 
    if(!props.firstName) {
      props.setError("Error: Please enter your name");
      return;
    }
    if (!props.addressLine1) {
      props.setError("Error: Please enter your address");
      return;
    }
    if (!props.city) {
      props.setError("Error: Please enter your city");
      return;
    }
    if (!props.state) {
      props.setError("Error: Please select your state");
      return;
    }
    if (!props.zipCode) {
      props.setError("Error: Please enter your zip code");
      return;
    }
    if(!props.email) {
      props.setError("Error: Please enter a valid email");
      return;
    }

    props.setError(undefined);
    setAddressShown(false);
    props.setRefresh((r) => !r);
    setPaymentShown(true);
    
    
  };



  return (
    <Box>
      <HStack bg="gray.100" rounded="md">
        <Image
          src={props.product.photoURLs[0]}
          roundedBottomLeft="md"
          roundedTopLeft={"md"}
          maxWidth="120px"
          height="120px"
        />
        <HStack flex={1} padding="3" justifyContent={"space-between"}>
          <VStack alignItems={"flex-start"}>
            <Text fontSize="sm"> {props.product.name} </Text>
            <Text fontSize="xs"> QTY: {props.quantity} </Text>
          </VStack>
          <Text>{Numeral(price).format("$0,0.00")}</Text>
        </HStack>
      </HStack>
      <Spacer height={"3"} />
      <Text align={"left"} fontSize={"sm"}>
        Shipping
      </Text>
      <Spacer height={"2"} />
      {!showAddress ? (
        <HStack
          padding="3"
          bg="gray.200"
          rounded="md"
          justifyContent={"space-between"}
          onClick={toggleExpandShipping}
        >
          <Text fontSize={"sm"}>
            {props.firstName
              ? `${props.firstName}, ${props.addressLine1}`
              : "Add Shipping Address"}
          </Text>
          {/* <IconButton icon={<FaChevronDown/>} aria-label="expand-shipping" bg="transparent"/> */}
        </HStack>
      ) : (
        <ShippingForm
          firstName={props.firstName}
          setFirstName={props.setFirstName}
          addressLine1={props.addressLine1}
          setAddressLine1={props.setAddressLine1}
          addressLine2={props.addressLine2}
          setAddressLine2={props.setAddressLine2}
          city={props.city}
          setCity={props.setCity}
          state={props.state}
          setUSState={props.setUSState}
          zipCode={props.zipCode}
          setZipCode={props.setZipCode}
          email={props.email}
          setEmail={props.setEmail}
          handleSubmitAddress={handleSubmitAddress}
          error={props.error}
          setError={props.setError}
        />
      )}
      <Spacer height={"3"} />
      <Text align={"left"} fontSize={"sm"}>
        Payment
      </Text>
      <Spacer height={"2"} />
      {!showPayment ? (
        <HStack
          padding="3"
          bg="gray.200"
          rounded="md"
          justifyContent={"space-between"}
        >
          <Text fontSize={"sm"}>Add Payment Method</Text>
          {/* <IconButton icon={<FaChevronDown/>} aria-label="expand-shipping" bg="transparent"/> */}
        </HStack>
      ) : (
        <VStack
          padding="3"
          bg="gray.50"
          rounded="md"
          justifyContent={"space-between"}
        >
          <Text align="left" fontSize={"sm"}>
            Add Payment Method
          </Text>
          {props.clientSecret && <Elements stripe={stripePromise} options={{ clientSecret: props.clientSecret }}>
            <CheckoutForm
              clientSecret={props.clientSecret}
              onPaymentComplete={props.onPaymentComplete}
              refresh={props.refresh}
              setRefresh={props.setRefresh}
              purchase={props.purchase}
              stripePurchaseId={props.stripePurchaseId}
            />
          </Elements>
          }
        </VStack>
      )}

      {/* <HStack width="100%" justify="space-evenly">
        <Button width="60%" size="lg" onClick={props.snapToNext}>
          Checkout
        </Button>
      </HStack> */}
    </Box>
  );
}

const stripePromise = StripeGateway.init()
.then((res) => {
  console.log("PK RES", res);
  return validateServiceResponse(res);
})
.then((pk) => {
  console.log("PK", pk);
  if (!pk) return Promise.reject();
  return loadStripe(pk);
});

export default ProductOrderSummary;
