/* eslint-disable camelcase */
module.exports = {
  purple: "#7F22FF",
  turquoise: "#17EEBA",
  turquoise_dark: "#0FDEAC",
  red: "#FF6868",
  black: "#1F2E41",
  grey_dark: "#354E6F",
  grey: "#5B6C84",
  grey_light: "#A2B3CB",
  grey_ultralight: "#D0DCE7",
  white: "#FFFFFF",
  white_alt: "#F1F5F8",
};
