import _ from "lodash";
import {
  animated,
  useSpring,
  SpringValue,
} from "react-spring";
const img = require("../../assets/animations/fire.png");

export const SingleFlame = ({
  animRefs,
  index,
}: {
  animRefs: React.MutableRefObject<SpringValue<number>[]>;
  index: number;
}) => {

  const initialY = 600;
  const initialX = 250 + _.random(-30,50);

  let totalUpwards = 0;
  let totalX = 0;

  const startTime = new Date().getTime() / 1000;
  let lastTime = startTime;

  const { upwards } = useSpring({
    config: { duration: 3500 },
    from: { upwards: 0 },
    to: { upwards: 800 },
  });

  const rand = _.random(-5,5);

  const transform = upwards.to((upwardsValue) => {
    const currentTime = new Date().getTime() / 1000;
    const duration = currentTime - lastTime;

    const verticalTraveled = upwardsValue * duration;
    const xTraveled = rand * Math.cos(((upwardsValue) * 2 * Math.PI) / 300);

    totalUpwards += verticalTraveled;
    totalX += xTraveled;

    lastTime = currentTime;

    if (totalUpwards > 1500) {
      totalUpwards = 0;
    }

    const finalY = initialY - totalUpwards;
    const finalX = initialX + totalX;

    return `translate3d(${finalX}px, ${initialY-1.4*upwardsValue}px, 0)`;
  });

  const opacity = upwards.to((upwardsValue) => {
    const o = (0-upwardsValue)/700 + 1;
    return o;
  });

  animRefs.current[index] = upwards;

  return (
    <animated.img
      src={img}
      alt="fire"
      width="50px"
      height="70px"
      style={{
        position: "absolute",
        transform,
        opacity,
      }}
    />
  );
};

