import React, { useEffect, useRef, useState } from "react";
import StreamContent from "./StreamContent";
import { FlexBox, StreamContentContainer } from "../../../styles";
import firebase from "firebase/app";
import "firebase/auth";
import OverlayContent from "./OverlayContent";
import useFirestore from "../../../hooks/useFirestore";
import { SingleFlame } from "../../molecules/SingleFlame";
import { SpringValue } from "react-spring";
import FireAnimationLayer from "./FireAnimationLayer";
import { IProduct, IShow } from "../../../types";
import { IShowEvent } from "../../../types/IShowEvent";
import PurchaseAnimationLayer from "./PurchaseAnimationLayer";

const Stream = ({
  channel,
  token,
  onOpen,
  handleSelectProduct,

}: {
  channel: string;
  token: string;
  onOpen: () => void;
  handleSelectProduct: (product: IProduct) => void
}) => {

  const [visible, setVisible] = useState(false);

  /// FIRE ANIMATION STUFF ////

  const click = useRef(0);
  const count = useRef(30).current;

  const fireAnimRefs = useRef<SpringValue<number>[]>(
    new Array(count).fill(null)
  );

  const purchaseAnimRef = useRef<SpringValue<number> | null>(null);
  const purchaseAnimSubjectRef = useRef<IShowEvent | null>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log("setting visible");
      setVisible(true);
    }, 4000);

    // return clearTimeout(timeout);
  }, []);


  const animate = () => {
    // setVisible(true);
    triggerFireAnimation(fireAnimRefs.current[click.current]);
    click.current = (click.current + 1) % count;
  };

  const triggerFireAnimation = (anim: SpringValue<number>) => {
    console.log("animated");
    if(anim && anim.start) {
      anim.finish();
      anim.reset();
      anim.start();
    }
  };

  const triggerPurchaseAnimation = () => {
    if(purchaseAnimRef.current) {
      purchaseAnimRef.current.finish();
      purchaseAnimRef.current.reset();
      purchaseAnimRef.current.start();
    }
  };

  const animatePurchase = (purchase: IShowEvent) => {
    purchaseAnimSubjectRef.current = purchase;
    triggerPurchaseAnimation();
  };


  //////////////////////////////

  return (
    <>
      <FlexBox
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 4,
          pointerEvents: "none",
          // contentVisibility: visible ? "visible" : "hidden",
        }}
      >
        <FireAnimationLayer animRefs={fireAnimRefs} />
        <PurchaseAnimationLayer
          animRef={purchaseAnimRef}
          purchaseRef={purchaseAnimSubjectRef}
        />
      </FlexBox>

      {channel ? (
        <>
          <StreamContent channel={channel} token={token} onOpen={onOpen} />
        </>
      ) : (
        <StreamContentContainer>
          <div style={{ color: "white", fontSize: 20, marginBottom: 30 }}>
            Entering Stream
          </div>
        </StreamContentContainer>
      )}
    </>
  );
};

export default Stream;

// const FireAnimationContainer = styled.div`
//   .fire-animation-class {
//     position: absolute;
//     left: 0;
//     right: 0;
//     width: ${(props) => props.width}px;
//     height: 600px;
//     bottom: 0;
//   }
// `;
