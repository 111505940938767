import { Box, Button, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ShowGateway } from "../../api/ShowGateway";
import { getQueryFromSearch } from "../../utils/getQuery";
import { AlertModal } from "../../components/molecules/AlertModal";
import Stream from "../../components/organisms/Show";
import { Placeholder } from "../../components/organisms/Show/Placeholder";
import ProductModal from "../../components/organisms/ProductModal";
import { IProduct, IShow } from "../../types";
import { loadStripe } from "@stripe/stripe-js";
import OnboardingModal from "../../components/organisms/OnboardingModal";
import firebase from "firebase/app";
import "firebase/auth";
import { UserGateway } from "../../api/UserGateway";
import { AuthGateway } from "../../api/AuthGateway";
import { validateServiceResponse } from "../../utils";
import { isMobile } from "react-device-detect";
import AppUpsellModal from "../../components/organisms/AppUpsellModal.tsx";
import _ from "lodash";



const ShowScreen = () => {
  const [streamToken, setStreamToken] = useState<string | null>(null);
  const [show, setShow] = useState<IShow | undefined>();
  const query = getQueryFromSearch();
  const showId = _.toString(query.show);

  useEffect(() => {
    if (showId) {
      ShowGateway.getShowById(showId)
      .then((showResponse) => {
        if (showResponse.success && showResponse.payload) {
          setShow(showResponse.payload);
        }
      })
      .then(() => ShowGateway.requestAgoraToken(showId))
      .then((tokenResponse) => {
        if (tokenResponse.success && tokenResponse.payload) {
          setStreamToken(tokenResponse.payload.token);
        } else {
          throw new Error("Missing");
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        console.log(err);
        alert("Unable to load stream.");
      });
    }
  }, [showId]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedProduct, setSelectedProduct] = useState<IProduct>();
  const [justCheckedOut, setJustCheckedOut] = useState<boolean>(false);

  const { isOpen: productModalOpen, onOpen: openProductModal, onClose: closeProductModal} = useDisclosure();
  const {
    isOpen: onboardingModalOpen,
    onOpen: openOnboardingModal,
    onClose: closeOnboardingModal,
  } = useDisclosure();

  const {
    isOpen: upsellModalOpen,
    onOpen: openUpsellModal,
    onClose: closeUpsellModal,
  } = useDisclosure();

  const onSelectProduct = (product: IProduct) => {
    console.log(product);
    setSelectedProduct(product);
    openProductModal();
    // openOnboardingModal(); //
    // setJustCheckedOut(true); //
  };

  const handleProductModalClose = (justCheckedOut?: boolean) => {
    closeProductModal();
    getCurrentUser()
    .then((user) => {
      if(!user) {
        openOnboardingModal();
        setJustCheckedOut(!!justCheckedOut);
      } else {
        openUpsellModal();
        setJustCheckedOut(!!justCheckedOut);
      }
    })
    .catch((err) => {
      openUpsellModal();
      setJustCheckedOut(!!justCheckedOut);
    });

  };

  const [openedOnce, setOpenedOnce] = useState(false);

  const handleOnbardingModalClose = () => {
    closeOnboardingModal();
    setJustCheckedOut(false);
    if(openedOnce) {
      openUpsellModal();
    }
    setOpenedOnce(true);
  };

  const getCurrentUser = async () => {
    const userRes = await AuthGateway.getCurrentUser();
    return validateServiceResponse(userRes);
  };

  const logout = async () => {
    await firebase.auth().signOut();
  };

  console.log("SHOW", show);
  
  return (
    <AppContainer>
      {/* {!isMobile && <Button onClick={logout}>Logout</Button>} */}
      <div className={"inner-container"} id={"global-inner-container"}>
        <AlertModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />

        {show && streamToken ? (
          <Stream
            channel={show.agoraChannel}
            token={streamToken}
            onOpen={onOpen}
            handleSelectProduct={onSelectProduct}
          />
        ) : (
          <Placeholder />
        )}
      </div>
      {selectedProduct && (
        <ProductModal
          isOpen={productModalOpen}
          handleClose={handleProductModalClose}
          product={selectedProduct}
        />
      )}
      <OnboardingModal
        isOpen={onboardingModalOpen}
        handleClose={handleOnbardingModalClose}
        justCheckedOut={justCheckedOut}
      />
      <AppUpsellModal
        isOpen={upsellModalOpen}
        handleClose={closeUpsellModal}
        justCheckedOut={justCheckedOut}
      />
    </AppContainer>
  );
};

export const AppContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  max-height: -webkit-fill-available;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  .inner-container {
    width: 100%;
    height: 100%;
    min-height: -webkit-fill-available;
    max-height: 100vh;
    max-width: 50vh;
    background-color: white;
    position: relative;
  }
`;


export default ShowScreen;