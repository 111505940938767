import styled from "styled-components";
import { theme } from "./theme.js";

export const FlexBox = styled.div`
  display: flex;
  ${(props) => {
    if (props.flip) {
      return `
          flex-direction: ${props.direction || "row"} !important;
          @media only screen and (max-width: ${props.flip}px) {
            flex-direction: ${
  !props.direction
    ? "column"
    : props.direction === "row"
      ? "column"
      : "row"
} !important;
          }
      `;
    }
    return `
      flex-direction: ${props.direction || "row"};
    `;
  }};
  ${(props) => {
    if (props.fluid) {
      return "width: 100%;";
    }
    return "";
  }}
  flex-wrap: ${(props) => props.wrap || "wrap"};
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: ${(props) => props.align || "center"};
  flex: 1;
`;

export const StreamContentContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  justify-content: center;
  align-items: center;
  display: flex;
`;
