import { useState, useEffect } from "react";
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  MicrophoneAudioTrackInitConfig,
  CameraVideoTrackInitConfig,
  IMicrophoneAudioTrack,
  ICameraVideoTrack,
  ILocalVideoTrack,
  ILocalAudioTrack,
} from "agora-rtc-sdk-ng";

export default function useAgora(
  role: "buyer" | "seller",
  appid: string,
  channel: string,
  token: string
): {
  client: IAgoraRTCClient;
  localAudioTrack: ILocalAudioTrack | undefined;
  localVideoTrack: ILocalVideoTrack | undefined;
  joinState: boolean;
  leave: () => Promise<void>;
  join: () => Promise<void>;
  remoteUsers: IAgoraRTCRemoteUser[];
} {
  const [client] = useState<IAgoraRTCClient>(
    AgoraRTC.createClient({ codec: "vp8", mode: "live" })
  );
  const [localVideoTrack, setLocalVideoTrack] = useState<
    ILocalVideoTrack | undefined
  >(undefined);
  const [localAudioTrack, setLocalAudioTrack] = useState<
    ILocalAudioTrack | undefined
  >(undefined);

  // console.log("USE AGORA");
  // console.log(role);
  // console.log(client);
  // console.log(localVideoTrack);

  const [joinState, setJoinState] = useState(false);

  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);

  async function createLocalTracks(
    audioConfig?: MicrophoneAudioTrackInitConfig,
    videoConfig?: CameraVideoTrackInitConfig
  ): Promise<[IMicrophoneAudioTrack, ICameraVideoTrack]> {
    const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack(
      audioConfig
    );
    const cameraTrack = await AgoraRTC.createCameraVideoTrack(videoConfig);

    console.log("SETTING LOCAL TRACKS");
    setLocalAudioTrack(microphoneTrack);
    setLocalVideoTrack(cameraTrack);

    return [microphoneTrack, cameraTrack];
  }

  useEffect(() => {
    if (client) {
      console.log("IS THIS DOING SOMETHING");
      if (role === "seller") {
        createAndPublishTracks();
      } else {
        client.setClientRole("audience");
      }
    }
  }, [client, role]);

  async function createAndPublishTracks() {
    if (role === "seller") {
      await client.setClientRole("host");
      const tracks = await createLocalTracks(
        {
          encoderConfig: "speech_standard",
        },
        {
          encoderConfig: {
            width: 900,
            height: 1500,
            frameRate: 15,
            bitrateMin: 600,
            bitrateMax: 1000,
          },
        }
      );
      await client.publish(tracks);
    }
  }

  async function join() {
    if (!client) return;

    // console.log("IN AGORA JOIN");
    // console.log(role);

    if (role === "seller") {
      await client.setClientRole("host");
      const tracks = await createLocalTracks(
        {
          encoderConfig: "speech_standard",
        },
        {
          encoderConfig: {
            width: 900,
            height: 1500,
            frameRate: 15,
            bitrateMin: 600,
            bitrateMax: 1000,
          },
        }
      );
      await client.join(appid, channel, token || null);
      await client.publish(tracks);
    } else {
      await client.setClientRole("audience");
      await client.join(appid, channel, token || null);
    }

    // (window as any).client = client;

    //  (window as any).videoTrack = cameraTrack;

    // (window as any).localStream = microphoneTrack.getMediaStreamTrack();
    setJoinState(true);
  }

  async function leave() {
    if (localAudioTrack) {
      localAudioTrack.stop();
      localAudioTrack.close();
    }
    if (localVideoTrack) {
      localVideoTrack.stop();
      localVideoTrack.close();
    }
    setRemoteUsers([]);
    setJoinState(false);
    await client?.leave();
  }

  useEffect(() => {
    if (!client) return;

    const dictFromArray = (array: IAgoraRTCRemoteUser[]) => {
      const remoteUsersDict: { [uid: string]: IAgoraRTCRemoteUser } = {};
      array.forEach((remoteUser) => {
        remoteUsersDict[remoteUser.uid.toString()] = remoteUser;
      });
      return remoteUsersDict;
    };

    setRemoteUsers(client.remoteUsers);

    const handleUserPublished = async (
      user: IAgoraRTCRemoteUser,
      mediaType: "audio" | "video"
    ) => {
      await client.subscribe(user, mediaType);
      console.log(user.uid);
      // toggle rerender while state of remoteUsers changed.
      setRemoteUsers((remoteUsers) => client.remoteUsers);
    };
    const handleUserUnpublished = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers((remoteUsers) => client.remoteUsers);
    };
    const handleUserJoined = (user: IAgoraRTCRemoteUser) => {
      console.log(user.uid);
      console.log("REMOTE USERS JOINED");
      setRemoteUsers((remoteUsers) => [...remoteUsers, user]);
    };
    const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
      //console.log(''+ user.uid + 'left')
      console.log("REMOTE USERS LEFT");
      setRemoteUsers((remoteUsers) => remoteUsers.filter((remoteUser) => remoteUser.uid !== user.uid));
    };
    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.on("user-joined", handleUserJoined);
    client.on("user-left", handleUserLeft);

    return () => {
      client.off("user-published", handleUserPublished);
      client.off("user-unpublished", handleUserUnpublished);
      client.off("user-joined", handleUserJoined);
      client.off("user-left", handleUserLeft);
    };
  }, [client]);

  return {
    client,
    localAudioTrack,
    localVideoTrack,
    joinState,
    leave,
    join,
    remoteUsers,
  };
}
