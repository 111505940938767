import {
  ILocalVideoTrack,
  IRemoteVideoTrack,
  ILocalAudioTrack,
  IRemoteAudioTrack,
} from "agora-rtc-sdk-ng";
import React, { useRef, useEffect, CSSProperties } from "react";

export interface VideoPlayerProps {
  videoTrack: ILocalVideoTrack | IRemoteVideoTrack | undefined;
  audioTrack: ILocalAudioTrack | IRemoteAudioTrack | undefined;
  isLocal: boolean;
}

const MediaPlayer = (props: VideoPlayerProps & { style: CSSProperties }) => {
  
  const container = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!container.current) return;
    props.videoTrack?.play(container.current, {
      
    });
    return () => {
      props.videoTrack?.stop();
    };
    
  }, [container, props.videoTrack]);

  useEffect(() => {
    if (!props.isLocal) {
      console.log("refreshed?");
      props.audioTrack?.play();
    }

    return () => {
      props.audioTrack?.stop();
    };
  }, [props.audioTrack, props.isLocal]);

  return <div ref={container} style={props.style}></div>;
};

export default MediaPlayer;
