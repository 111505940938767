import { ThemeProvider } from "styled-components";
import theme from "./styles/theme.js";
import ShowScreen from "./screens/ShowScreen";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import InstagramSignInPage from "./components/organisms/Instagram/InstagramSignInPage";

function App() {

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ShowScreen />} />
          <Route path="/instagram" element={<InstagramSignInPage/>}/>
        </Routes>
      </BrowserRouter>
     
    </ThemeProvider>
  );
}

export default App;

// height sizes here may cause some browser issues. Should work on most modern browsers but to get a proper height on ios devices this min height tool is needed



