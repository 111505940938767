import {
  Box,
  Button,
  Center,
  Input,
  InputGroup,
  InputRightElement,
  theme,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { useState } from "react";
import { FaInstagram } from "react-icons/fa";
import {
  IInstagramService,
} from "../../../hooks/useInstagram";
import { getQueryFromSearch } from "../../../utils/getQuery";

export const InstagramSignIn = ({ ig }: { ig: IInstagramService }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [code, setCode] = useState("");

  const [show, setShow] = useState(false);

  const query = getQueryFromSearch();

  const shopId = _.toString(query.shop);

  console.log("QUERY", query);

  const handleTogglePassword = () => {
    setShow((s) => !s);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (username && password && query.shop) {
      await ig.signIn(username, password, shopId);
    }
    setPassword("");
    setLoading(false);
  };

  const handleTwoFactorSubmit = async () => {
    setLoading(true);
    if (ig.twoFactor && code) {
      await ig.verifyTwoFactor(code, shopId);
    }
    setCode("");
    setLoading(false);
  };

  console.log("IG TWO FACTOR", ig.twoFactor);
  return (
    <VStack
      padding={theme.space[10]}
      borderRadius={theme.space[2]}
      bg={theme.colors.gray[200]}
    >
      <Center height="100%" flexDir={"column"} marginBottom={theme.space[5]}>
        <FaInstagram size={30} />
        {ig.user && (
          <Center textAlign={"center"} flexDir="column" marginTop="4">
            Signed in to Instagram as {ig.user.username}!
          </Center>
        )}
      </Center>
      {!ig.user
        && (ig.twoFactor ? (
          <VStack height="100%" align={"center"} justify="center">
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type="text"
                placeholder="Enter verification code "
                bgColor={"white"}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </InputGroup>

            <Button
              colorScheme={"blue"}
              onClick={handleTwoFactorSubmit}
              disabled={loading}
            >
              Submit Two Factor
            </Button>
            {/* <Button colorScheme={"blue"} onClick={createLive}>
            Create Live
          </Button> */}
            <Box maxW={300} textAlign="center">{ig.error && <div>Error: {ig.error}</div>}</Box>
          </VStack>
        ) : (
          <VStack height="100%" align={"center"} justify="center">
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type="text"
                placeholder="Enter username"
                bgColor={"white"}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </InputGroup>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder="Enter password"
                bgColor={"white"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleTogglePassword}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Button
              colorScheme={"blue"}
              onClick={handleSubmit}
              disabled={loading}
            >
              Sign In
            </Button>
            {/* <Button colorScheme={"blue"} onClick={createLive}>
            Create Live
          </Button> */}
            <Box maxW={300} textAlign="center">{ig.error && <div>Error: {ig.error}</div>}</Box>
          </VStack>
        ))}
    </VStack>
  );
};
