import {
  Button,
  Center,
  HStack,
  theme,
  VStack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import firebase from "firebase";
import "firebase/auth";
// import "../App.css";
import { InstagramSignIn } from "./InstagramSignIn";
import { SoGoodSignIn } from "./SoGoodSignIn";
import { IInstagramService, useInstagram } from "../../../hooks/useInstagram";
import { FaInstagram } from "react-icons/fa";
import { ShowGateway } from "../../../api/ShowGateway";
import { getQueryFromSearch } from "../../../utils/getQuery";
import _ from "lodash";

function InstagramSignInPage() {
  const [user, setUser] = useState<firebase.User | null>(null);

  const ig = useInstagram();



  useEffect(() => {
    logout();
  }, []);

  useEffect(() => {
    if(firebase.auth().currentUser?.isAnonymous) {
      setUser(null);
      firebase.auth().signOut();
    }
  }, []);

  const logout = async () => {
    setUser(null);
    firebase.auth().signOut();
  };

  return (
    <VStack>
      <Header logout={logout} ig={ig} />
      <Center w="100%" h={"90vh"} bg={theme.colors.gray[100]}>
        {/* {
          firebase.auth().currentUser && !firebase.auth().currentUser?.isAnonymous ? <InstagramSignIn ig={ig} /> :  <SoGoodSignIn user={user} setUser={setUser} />
        } */}
        <InstagramSignIn ig={ig} />
      </Center>
    </VStack>
  );
}

export default InstagramSignInPage;

function Header({
  logout,
  ig,
}: {
  logout: () => Promise<void>;
  ig: IInstagramService;
}) {
  const query = getQueryFromSearch();

  const [shopName, setShop] = useState<string | undefined>("");
  useEffect(() => {
    if(query.shop) {
      ShowGateway.getShop(_.toString(query.shop)).then((shop) =>
        setShop(shop.payload?.name)
      );
    }
  }, [query.shop]);
  return (
    <HStack
      w="100%"
      h="10vh"
      bg={theme.colors.gray[100]}
      position="relative"
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Text alignSelf={"center"} fontSize={theme.fontSizes["xl"]}>
        Instagram Live Sign In
      </Text>
      <HStack height="100%" marginLeft={"auto"} position="absolute" left={15}>
        <FaInstagram size={30} />
        <Text>
          {shopName}
        </Text>
      </HStack>

    </HStack>
  );
}
