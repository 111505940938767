import _ from "lodash";
import React, { useRef } from "react";
import { isMobile } from "react-device-detect";
import { animated, SpringValue, useSpring } from "react-spring";
import { FlexBox } from "../../../styles";
import { IShowEvent } from "../../../types/IShowEvent";
import { SingleFlame } from "../../molecules/SingleFlame";
import { VStack, Image, HStack, Text, Spacer, Button } from "@chakra-ui/react";
import { IProduct } from "../../../types";
import Lottie from "lottie-react";


const PurchaseAnimationLayer = ({
  animRef,
  purchaseRef,
}: {
  animRef: React.MutableRefObject<SpringValue<number> | null>;
  purchaseRef: React.MutableRefObject<IShowEvent | null>;
}) => {

  const ref=useRef<HTMLDivElement>(null);
  const lottieRef = useRef();

  const { innerHeight, innerWidth } = window;

  const initialY = 600;
  const initialX = 250 + _.random(-30, 50);

  const totalUpwards = 0;
  const totalX = 0;

  const startTime = new Date().getTime() / 1000;
  const lastTime = startTime;

  const { upwards } = useSpring({
    config: { duration: 5000 },
    
    from: { upwards: 0 },
    to: { upwards: 1000 },
  });

  const rotation = upwards.to((upwardsValue) => {
  
    let r = 0;
    const finalR = 720;

    if (upwardsValue < 400) {
      r = 0;
    } else {
      r = (finalR * (upwardsValue - 400)) / 600;
    }

    return `rotateY(${r}deg)`;
  });

  const transform = upwards.to((upwardsValue) => {

    // if(upwardsValue===1) {
    //   lottieRef.current?.play();
    // } 
    // if(upwardsValue===900) {
    //   lottieRef.current?.stop();
    // }
    let x = 0;
    let y = 0;
    const r = 0;
    const finalX = (ref.current?.clientWidth || 0)/2 - 120;
    const finalY = 0;
    if(upwardsValue<400) {
      x = finalX + (400-upwardsValue);
      y = finalY + 3*(400-upwardsValue);

    } else {
      x = finalX;
      y = finalY;
   
    }

    return `translate3d(${x}px, ${y}px, 0)`;
  });

  const opacity = upwards.to((upwardsValue) => {
    if(!purchaseRef.current || !purchaseRef.current.author.photoURL || !purchaseRef.current.author.username) {
      return 0;
    }
    if(upwardsValue < 400) {
      return upwardsValue/400;
    } else if(upwardsValue < 800) {
      return 1;
    } else {
      return (1000-upwardsValue)/200;
    }
  });

  animRef.current = upwards;

  return (
    <FlexBox
      ref={ref}
      style={{
        position: "absolute",
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 4,
        pointerEvents: "none",
      }}
    >
      <Lottie
        autoplay={false}
        style={{position: "absolute"}} 
        animationData={require("../../../assets/animations/stars.json")} 
      />
      <animated.div
        style={{
          display: "flex",
          width: "240px",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          opacity,
          transform,
        }}
      >
        <animated.img
          src={purchaseRef.current?.author.photoURL}
          alt="purchase-celebration"
          placeholder={
            "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
          }
          width="120px"
          height="120px"
          style={{
            borderRadius: "60px",
            maxWidth: "120px",
            transform: rotation,
          }}
        />

        {purchaseRef.current?.payload.type === "purchase" && (
          <Text
            marginTop="md"
            fontSize={"xl"}
            fontWeight="800"
            color="white"
            textAlign="center"
            alignSelf="center"
            style={{
              textShadow: "-1px 1px 10px rgba(0, 0, 0, 0.75)",
            }}
          >
            {`${purchaseRef.current?.author.username} just bought ${purchaseRef.current?.payload.body.name} 🎉`}
          </Text>
        )}
      </animated.div>
    </FlexBox>
  );
};

export default PurchaseAnimationLayer;
