/* eslint-disable camelcase */
import { Box, Button } from "@chakra-ui/react";
import { PaymentRequestButtonElement, useStripe } from "@stripe/react-stripe-js";
import { PaymentRequest, Stripe } from "@stripe/stripe-js";
import _ from "lodash";
import { useEffect, useState } from "react";
import { FaApple, FaCreditCard } from "react-icons/fa";
import { StripeGateway } from "../../../api/StripeGateway";
import { IPurchaseDetails } from "../../../types/IPurchase";
import { validateServiceResponse } from "../../../utils";


type ApplePayButtonProps = {
  onSubmit : () => void
  purchase: IPurchaseDetails;
}



const ApplePayButton = (props: ApplePayButtonProps) => {

  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest| null>(null);

  console.log("PURCHASE CHANGING", props.purchase);

  useEffect(() => {
    console.log("resetting payment request");
    setPaymentRequest(null);
  }, [props.purchase]);

  useEffect(() => {
    console.log("useEFFECT rerunning");
    if (stripe) {
      console.log("useEFFECT rerunning2");
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: `${props.purchase.name} x${props.purchase.quantity}`,
          amount: _.toInteger(props.purchase.total * 100),
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestShipping: true,
        shippingOptions: [
          // The first shipping option in this list appears as the default
          // option in the browser payment interface.
          {
            id: "free-shipping",
            label: "Free shipping",
            detail: "",
            amount: 0,
          },
        ],
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, props.purchase]);

  if(!paymentRequest) {
    return <></>;
  }

  paymentRequest.on("paymentmethod", async(ev) => {
    console.log("ADDRESS", ev.shippingAddress);
    console.log("EMAIL", ev.payerEmail);
    const {shippingAddress, payerEmail, payerName} = ev;
    if(!shippingAddress || !payerEmail || !payerName) {
      ev.complete("fail");
      return;
    }
    // eslint-disable-next-line camelcase
    const { country, city, region: state, postalCode: postal_code, addressLine} = shippingAddress;
    if(!country || !city || !state || !postal_code || !addressLine || addressLine.length < 1) {
      ev.complete("fail");
      return;
    }
    const clientSecretRes = await StripeGateway.checkout({
      ...props.purchase, 
      shippingDetails: {
        address: {
          city, country, state, postal_code,
          line1: addressLine[0],
          line2: addressLine[1] || "",
        },
        email: payerEmail, 
        name: payerName, 
        phone: "1234567890",
      },

    }, payerEmail);
    const clientSecret = validateServiceResponse(clientSecretRes);
    if(clientSecret && stripe && clientSecret.paymentIntent) {
      const { paymentIntent, error: confirmError } =
          await stripe.confirmCardPayment(
            clientSecret.paymentIntent,
            // eslint-disable-next-line camelcase
            { payment_method: ev.paymentMethod.id },
            { handleActions: false }
          );

      console.log("PAYMENT INTENT", paymentIntent);
      if(confirmError) {
        ev.complete("fail");
      } else {
        console.log("PAYMENT SUCCESS");
        await StripeGateway.submitPurchase({
          id: (Math.random() + 1).toString(36).substring(7),
          details: props.purchase,
          stripePurchaseId: clientSecret.paymentIntentId,
        });
        props.onSubmit();
        ev.complete("success");
      }
    }

    
  });
  return (
    <Box width="45%">
      <PaymentRequestButtonElement
        options={{
          paymentRequest,
          style: {
            paymentRequestButton: {
              theme: "dark",
              height: "40px",
            },
          },
        }}
      />
    </Box>
    // <Button
    //   leftIcon={<FaApple/>}
    //   width="45%"
    //   marginTop="1"
    //   size="lg"
    //   onClick={handleSubmit}
    //   bg="black"
    //   color="white"
    // >
    //   Apple Pay
    // </Button>
  );
};

export default ApplePayButton;