import { Box, HStack, IconButton, Text } from "@chakra-ui/react";
import { FaChevronLeft } from "react-icons/fa";



type ProductModalHeaderProps = {
  goBack? : () => void 
  title?: string
}

const ProductModalHeader = (props: ProductModalHeaderProps) => {


  return (
    <HStack height="50" justifyContent={"space-between"}>
      {props.goBack ? (
        <IconButton
          icon={<FaChevronLeft />}
          aria-label={"back"}
          onClick={props.goBack}
          // bg="transparent"
        />
      ) : (
        <IconButton aria-label={"back"} visibility="hidden" />
      )}
      {
        <Text fontSize={"2xl"} alignSelf="center">
          {props.title}
        </Text>
      }
      <IconButton aria-label={"back"} visibility="hidden"/>
    </HStack>
  );
};

export default ProductModalHeader;