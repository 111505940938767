import querystring from "querystring";

export const getQueryFromSearch = () => {
  // eslint-disable-next-line no-undef
  const search = window.location.search;
  let searchValue = `${ search }`;

  if (searchValue.startsWith("?")) {
    searchValue = searchValue.slice(1);
  }

  return querystring.parse(searchValue) || {};
};
