/* eslint-disable @typescript-eslint/ban-types */
import { get, post } from ".";
import { IUser } from "../types";
import {
  failureServiceResponse,
  IServiceResponse,
} from "../types/IServiceResponse";
import firebase from "firebase/app";
import "firebase/auth";

export const AuthGateway = {

  getConfig : async () : Promise<IServiceResponse<{stripePublishableKey: string, minimumBuildNumber: number}>> => {
    try {
      return await get<
        IServiceResponse<{
          stripePublishableKey: string;
          minimumBuildNumber: number;
        }>
      >("/auth/config");
    } catch (err) {
      return failureServiceResponse("Unable to request code at this time");
    }
  },
 
  requestOtp: async (phone: string): Promise<IServiceResponse<{}>> => {
    try {
      return await post<IServiceResponse<{}>>("/auth/otp/request", { phone });
    } catch (err) {
      return failureServiceResponse("Unable to request code at this time");
    }
  },

  verifyOtp: async (
    phone: string,
    code: string
  ): Promise<IServiceResponse<{ token: string; user: IUser }>> => {
    try {
      return await post<IServiceResponse<{ token: string; user: IUser }>>(
        "/auth/otp/verify",
        {
          phone,
          code,
        }
      );
    } catch (err) {
      return failureServiceResponse("Unable to verify code at this time");
    }
  },

  addUserDetails: async (
    photoURL: string,
    username: string
  ): Promise<IServiceResponse<IUser>> => {
    try {
      return await post<IServiceResponse<IUser>>("/auth/add_details", {
        photoURL,
        username,
      });
    } catch (err) {
      return failureServiceResponse("Could not add user details");
    }
  },

  checkUsername: async (
    username: string
  ): Promise<IServiceResponse<{ isUnique: boolean }>> => {
    try {
      return await post<IServiceResponse<{ isUnique: boolean }>>(
        "/auth/check_username",
        { username }
      );
    } catch (err) {
      return failureServiceResponse("Could not validate username");
    }
  },

  getCurrentUser: async (): Promise<IServiceResponse<IUser>> => {
    try {
      //const token = await AsyncStorage.getItem("token");
      const firebaseId = await firebase.auth().currentUser?.getIdToken();
      if (firebaseId) {
        const res = await get<IServiceResponse<IUser>>("/auth/current_user");
        return res;
      } else {
        return failureServiceResponse("No firebase user saved locally");
      }
    } catch (err) {
      return failureServiceResponse("Unable to fetch Current User");
    }
  },

  registerUser: async (): Promise<IServiceResponse<IUser>> => {
    try {
      return await post<IServiceResponse<IUser>>("/auth/register", {});
    } catch (err) {
      return failureServiceResponse("pop");
    }
  },
};
