import { VStack, Spacer, HStack, IconButton, Button, Text, Image } from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Numeral from "numeral";
import React from "react";
import { FaCreditCard, FaMinus, FaPlus } from "react-icons/fa";
import { StripeGateway } from "../../../api/StripeGateway";
import { IProduct } from "../../../types";
import { IPurchaseDetails } from "../../../types/IPurchase";
import { validateServiceResponse } from "../../../utils";
import ApplePayButton from "./ApplePayButton";

const stripePromise = StripeGateway.init()
.then((res) => {
  console.log("PK RES", res);
  return validateServiceResponse(res);
})
.then((pk) => {
  console.log("PK", pk);
  if (!pk) return Promise.reject();
  return loadStripe(pk);
});

type ProductPreviewProps = {
  product: IProduct
  purchase: IPurchaseDetails;
  decrementQuantity: () => void
  quantity: number;
  incrementQuantity: () => void
  onSubmitApplePay: () => void
  snapToNext: () => void

}

function ProductPreview(props: ProductPreviewProps) {
  console.log("purchase", props.purchase);
  return (
    <VStack height={"100%"}>
      <HStack overflowX="scroll">
        {props.product.photoURLs.map((photoURL) => (
          <Image src={photoURL} width="100%" borderRadius={"md"} />
        ))}
      </HStack>

      <Text
        alignSelf={"flex-start"}
        align="left"
        fontSize="2xl"
        fontWeight="800"
      >
        {" "}
        {props.product.name}{" "}
      </Text>
      <Text align={"left"} fontWeight="600">
        {" "}
        {props.product.description}{" "}
      </Text>
      <Spacer flex={1} />
      <HStack width="100%" justify="space-between">
        <HStack>
          <Text
            fontSize="2xl"
            color="gray.400"
            fontWeight="800"
            style={{
              textDecorationLine: "line-through",
            }}
          >
            {Numeral(props.product.price).format("$0,0.00")}
          </Text>
          <Text fontSize="2xl" fontWeight="800">
            {Numeral(props.product.discount).format("$0,0.00")}
          </Text>
        </HStack>
        <HStack>
          <IconButton
            icon={<FaMinus />}
            aria-label="minus"
            rounded={"full"}
            onClick={props.decrementQuantity}
          />
          <Text width={"8"} fontSize={"2xl"} fontWeight="800">
            {props.quantity}
          </Text>
          <IconButton
            icon={<FaPlus />}
            aria-label="plus"
            rounded={"full"}
            onClick={props.incrementQuantity}
          />
        </HStack>
      </HStack>
      <Elements stripe={stripePromise}>
        <HStack width="100%" justify="space-evenly">
          <ApplePayButton 
            purchase={props.purchase}
            onSubmit={props.onSubmitApplePay}
          />

          <Button
            leftIcon={<FaCreditCard />}
            width="45%"
            height= "40px"
            size="lg"
            onClick={props.snapToNext}
            colorScheme="purple"
            color="white"
          >
            Checkout
          </Button>
        </HStack>
      </Elements>
    </VStack>
  );
}

export default ProductPreview;
  
  