import { colors } from "@react-spring/shared";
import React, { useEffect, useRef } from "react";
import useAgora from "../../../hooks/useAgora";
import { FlexBox, StreamContentContainer } from "../../../styles";
import theme from "../../../styles/theme";
import MediaPlayer from "../../molecules/MediaPlayer";
import Box from "../../atoms/Patterns/Base/Box";
import Text from "../../atoms/Patterns/Base/Text";

const StreamContent = ({
  channel,
  token,
  onOpen,
}: {
  channel: string;
  token: string;
  onOpen: () => void;
}) => {
  const { join, leave, client, remoteUsers } = useAgora(
    "buyer",
    "adce12b9bde94689bf67b8bd71b5a8d1",
    channel,
    token
  );

  useEffect(() => {
    join();
    return () => {
      leave();
    };
  }, []);

  // const container = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (!container.current) return;
  //   remoteUsers[0]?.videoTrack?.play(container.current, {});
  //   return () => {
  //     remoteUsers[0]?.videoTrack?.stop();
  //   };
  // }, [container, remoteUsers[0]]);

  // useEffect(() => {
  //   remoteUsers[0]?.audioTrack?.play();
  //   return () => {
  //     remoteUsers[0]?.audioTrack?.stop();
  //   };
  // }, [remoteUsers[0]]);

  console.log("REMOTE USERS", remoteUsers);

  return (
    <StreamContentContainer
      id={"stream-content"}
      style={{ backgroundColor: theme.colors.bg.dark }}
    > 
      {remoteUsers.length > 0
      && <MediaPlayer 
        audioTrack={remoteUsers[0].audioTrack}
        videoTrack={remoteUsers[0].videoTrack}
        isLocal={false}
        style={{
          width: "100%", 
          height: "100%",
        }}
      />
    
      }
    </StreamContentContainer>
  );
};

export default StreamContent;
