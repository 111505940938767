import React from "react";
import { isMobile } from "react-device-detect";
import { SpringValue } from "react-spring";
import { FlexBox } from "../../../styles";
import { SingleFlame } from "../../molecules/SingleFlame";

const FireAnimationLayer = ({
  animRefs,
}: {
    animRefs: React.MutableRefObject<SpringValue<number>[]>;
  }) => {
  return (
    <FlexBox
      style={{
        position: "absolute",
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 4,
        pointerEvents: "none",
      }}
    >
      {animRefs.current.map((anim, i) => (
        <SingleFlame key={i} index={i} animRefs={animRefs} />
      ))}
    </FlexBox>
  );
};

export default FireAnimationLayer;