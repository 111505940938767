import { VStack, Image, HStack, Text, Spacer, Button, Input, FormControl, FormLabel, FormHelperText, FormErrorMessage } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AuthGateway } from "../../../api/AuthGateway";
import { UserGateway } from "../../../api/UserGateway";
import colors from "../../../styles/colors";
import { validateServiceResponse } from "../../../utils";


type AddUserDetailsProps = {
  snapToNext: () => void;
  photoURL: string;
  setPhotoURL: React.Dispatch<React.SetStateAction<string>>;
  username: string | undefined;
  setUsername: React.Dispatch<React.SetStateAction<string | undefined>>;
  error: string | undefined;
  handleSubmit : () => Promise<void>
};

const AddUserDetails = (props: AddUserDetailsProps) => {




  return (
    <VStack width={"100%"} height={"100%"}>
      <Text fontSize={"2xl"} fontWeight="800">You're in!</Text>
      <Text fontSize={"md"}>
        Nice, you're sign up was successful! If you'd like, you can change your
        username below or just go with the default
      </Text>
      <Spacer />
      <Image
        referrerPolicy="no-referrer"
        maxWidth={"150px"}
        height={"150px"}
        src={props.photoURL}
        rounded="40px"
      />
      <Spacer />
      <VStack mt={"5"} width="80%" flex={1}>
        <FormControl isInvalid={!!props.error}>
          <Input
            id="username"
            type="text"
            placeholder="sk8erboi"
            value={props.username}
            onChange={(e) => props.setUsername(e.target.value)}
          />
          <FormHelperText>
            {props.error || "Customize a username!"}
          </FormHelperText>
        </FormControl>
        <Spacer />
        <Button
          onClick={props.handleSubmit}
          alignSelf={"stretch"}
          disabled={!!props.error}
          bgColor={colors.purple}
          color="white"
          size="lg"
        >
          Save
        </Button>
      </VStack>
    </VStack>
  );
};

export default AddUserDetails;
