import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { isMobile } from "react-device-detect";

export const AlertModal = ({
  isOpen,
  onOpen,
  onClose,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent width={isMobile ? "80%" : "40%"}>
        <ModalHeader>
          You must download the app to complete this action.
        </ModalHeader>

        <ModalBody>Download SoGood from the App Store!</ModalBody>

        <ModalFooter>
          <a
            href="https://apps.apple.com/us/app/sogood-live-video-shopping/id1609670141"
            target="_blank"
            rel="noreferrer"
          >
            <Button colorScheme="blue" mr={3}>
              Get the App
            </Button>
          </a>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
