import { VStack, Image, HStack, Text, Spacer, Button } from "@chakra-ui/react";
import React, { useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";

type CreateAccountProps = {
  snapToNext: () => void;
  justCheckedOut: boolean;
}

const CreateAccount = (props: CreateAccountProps) => {


  const signInWithGoogle = async () => {
    console.log("SIGNING IN");
    const provider = new firebase.auth.GoogleAuthProvider();
  
    const auth = firebase.auth();
    await auth.setPersistence("local");
    auth.signInWithPopup(provider)
    .then((authResult) => {
      console.log("SIGNED IN as ", authResult.user?.email);
    });

  };

  const signInWithApple = async () => {
    const auth = firebase.auth();
    const provider = new firebase.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    await auth.setPersistence("local");
    auth.signInWithPopup(provider)
    .then((result) => {
      const { credential, user } = result;
      console.log("SIGNED IN", user);
    });

  };

  return (
    <VStack width={"100%"} height={"100%"}>
      <Image
        maxWidth={"150px"}
        height={"150px"}
        src={require("../../../assets/images/sogood.png")}
        rounded="40px"
      />
      <VStack mt={"2"}>
        {props.justCheckedOut ? (
          <Text fontSize={"2xl"} fontWeight={"800"} textAlign="center">
            Your order was received. Sign up now for updates!
          </Text>
        ) : (
          <Text fontSize={"2xl"} fontWeight={"800"}>
            Sign up now for updates!
          </Text>
        )}

        <Text fontSize="sm">
          Don't miss another livestream, sign up with just one click.
        </Text>
      </VStack>
      <Spacer flex={1} />
      <VStack alignSelf={"stretch"} alignItems={"stretch"}>
        <Button
          bgColor="#EB5D3E"
          color="white"
          size="lg"
          onClick={signInWithGoogle}
        >
          Sign up with Google
        </Button>
        <Button
          bgColor="black"
          color="white"
          size="lg"
          onClick={signInWithApple}
        >
          Sign up with Apple
        </Button>
      </VStack>
    </VStack>
  );
};

export default CreateAccount;