import { Button, Spacer, Text } from "@chakra-ui/react";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { randomUUID } from "crypto";
import { useEffect, useState } from "react";
import { StripeGateway } from "../../../api/StripeGateway";
import { BillingDetails, IPurchaseDetails } from "../../../types/IPurchase";
import { validateServiceResponse } from "../../../utils";




type CheckoutFormProps = {
  refresh: boolean;
  clientSecret: string;
  purchase: IPurchaseDetails;
  stripePurchaseId: string | undefined;
  onPaymentComplete?: () => void;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const CheckoutForm = (props: CheckoutFormProps) => {

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if(!stripe) {
      return;
    }

    setIsLoading(true);

    stripe
    .retrievePaymentIntent(props.clientSecret)
    .then(({ paymentIntent }) => {
      if (!paymentIntent) {
        // setMessage(
        //   "Your payment was not successful, please try again."
        // );
        return;
      }
      
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage(
            "Please add a payment method"
          );
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
      setIsLoading(false);
    });


  }, [stripe, props.refresh]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    setMessage("Payment Processing");

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // eslint-disable-next-line camelcase
        return_url: "http://localhost:3000",
      },
      redirect: "if_required",
    });

    console.log("STRIPE PURCHASE ID", props.stripePurchaseId);

    if(!error) {
      props.stripePurchaseId
        && (await StripeGateway.submitPurchase({
          id: (Math.random() + 1).toString(36).substring(7),
          details: props.purchase,
          stripePurchaseId: props.stripePurchaseId,
        }));
      setMessage("Payment Succeeded!");

      props.onPaymentComplete && props.onPaymentComplete();
    }

    // FIX THIS

    // 

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    // console.log("BIG ERROR", error);
    // if (error &&( error.type === "card_error" || error.type === "validation_error")) {
    //   setMessage(error.message || "An unexpected error occurred");
    // } else {
    //   setMessage("An unexpected error occurred.");
    // }

    setIsLoading(false);
  };

  return (
    
    <form id="payment-form">
      <PaymentElement id="payment-element" />
      <Text>{message}</Text>
      <Spacer height="3" />
      <Button
        disabled={!stripe}
        colorScheme="purple"
        color="white"
        onClick={handleSubmit}
      >
          Submit
      </Button>
    </form>
    
  );

};

export default CheckoutForm;