/* eslint-disable camelcase */
import {
  Box,
} from "@chakra-ui/react";
import { BottomSheet } from "react-spring-bottom-sheet";
import { IProduct } from "../../../types";
import "react-spring-bottom-sheet/dist/style.css";
import { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CreateAccount from "./CreateAccount";
import DownloadApp from "../AppUpsellModal.tsx/DownloadApp";
import AddUserDetails from "./AddUserDetails";
import { SheetContent } from "../../molecules/SheetContent";
import firebase from "firebase/app";
import "firebase/auth";
import { AuthGateway } from "../../../api/AuthGateway";
import { validateServiceResponse } from "../../../utils";
import _ from "lodash";


type OnboardingModalProps = {
  isOpen: boolean;
  justCheckedOut: boolean;
  handleClose: () => void;
};

const OnboardingModal = (props: OnboardingModalProps) => {
  const carouselRef = useRef<Carousel>(null);


  const snapToNext = () => {
    carouselRef.current?.increment();
  };

  const snapToPrev = () => {
    carouselRef.current?.decrement();
  };

  const [userPhotoURL, setUserPhotoURL] = useState<string>(
    "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
  );
  const [username, setUsername] = useState<string>();
  const [error, setError] = useState<string>();
  const [isFirstOpen, setFirstOpen] = useState<boolean>(true);

  const getCurrentUser = async () => {
    const userRes = await AuthGateway.getCurrentUser();
    return validateServiceResponse(userRes);
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
      
      getCurrentUser().then((user) => {
        
        if(_.isUndefined(user)) {
          if (firebaseUser && !firebaseUser.isAnonymous) {
            AuthGateway.registerUser().then((userResponse) => {
              const newUser = validateServiceResponse(userResponse);
              if (newUser) {
                setUsername(newUser.username);
                firebaseUser.photoURL
                         && setUserPhotoURL(firebaseUser.photoURL);
              }
              snapToNext();
              return;
            });
          }
        } else {
          props.handleClose();
        }

        

      });  

    });

    return unsubscribe;
  }, []);

  const checkUsername = (username: string) => {
    AuthGateway.checkUsername(username).then((uniqueResponse) => {
      const data = validateServiceResponse(uniqueResponse);
      console.log(data);
      if (data && data.isUnique) {
        setError(undefined);
      } else {
        setError("Sorry, that username is taken");
      }
    });
  };

  const handleSubmit = async () => {
    console.log("submitting");
    if (userPhotoURL && username && !error) {
      AuthGateway.addUserDetails(userPhotoURL, username)
      .then(() => {
        props.handleClose();
      })
      .catch(() => {
        setError("There was an unexpected error");
      });
    }
    
  };

  useEffect(() => {
    if(username) {
      checkUsername(username);
    } else {
      setError("Please enter a valid username");
    }
  }, [username]);

  const handleDismiss = async () => {
    await handleSubmit();
    props.handleClose();
  };

  return (
    <BottomSheet
      open={props.isOpen}
      onDismiss={handleDismiss}
      expandOnContentDrag
      // defaultSnap={({ snapPoints, lastSnap }) =>
      //   lastSnap ?? Math.min(...snapPoints)
      // }
      // snapPoints={({ maxHeight }) => [
      //   maxHeight - maxHeight / 5,
      //   maxHeight * 0.6,
      // ]}
    >
    
      <Carousel
        ref={carouselRef}
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        swipeable={false}
      >
        <SheetContent height="100%">
          <Box width={"100%"} px={5} py={5}>
            <CreateAccount
              justCheckedOut={props.justCheckedOut}
              snapToNext={snapToNext}
            />
          </Box>
        </SheetContent>
        <SheetContent height="100%">
          <Box width={"100%"} px={5} py={5}>
            <AddUserDetails
              photoURL={userPhotoURL}
              setPhotoURL={setUserPhotoURL}
              username={username}
              setUsername={setUsername}
              error={error}
              handleSubmit={handleSubmit}
              snapToNext={snapToNext}
            />
          </Box>
        </SheetContent>
      </Carousel>
      
    </BottomSheet>
  );
};

export default OnboardingModal;
