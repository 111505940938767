import { VStack, Image, HStack, Text, Spacer, Button } from "@chakra-ui/react";
import React from "react";

type DownloadAppProps = {
  justCheckedOut: boolean
}

const DownloadApp = (props: DownloadAppProps) => {

  const defaultLinkStyles = {
    background: "url(https://linkmaker.itunes.apple.com/images/badges/en-us/badge_appstore-lrg.svg) no-repeat",
    backgroundSize: "contain",
    display: "inline-block",
    overflow: "hidden",
    textDecoration: "none",
    height: "100%",
    width: "100%",
    padding: "5px",
  };
  return (
    <VStack width={"100%"} height={"100%"}>
      <Image
        maxWidth={"150px"}
        height={"150px"}
        src={require("../../../assets/images/sogood.png")}
        rounded="40px"
      />
      <VStack mt={"2"}>
        {props.justCheckedOut ? (
          <Text fontSize={"2xl"} fontWeight={"800"} textAlign="center">
            Your order was received! It's just better in the app
          </Text>
        ) : (
          <Text fontSize={"2xl"} fontWeight={"800"}>
            It's just better in the app
          </Text>
        )}
        <Text fontSize={"md"}>More sellers, shows, and products</Text>
      </VStack>
      <Spacer flex={1} />
      <div style={{ height: 75, width: 255, display: "inline-block" }}>
        <a
          style={defaultLinkStyles}
          href={
            "https://apps.apple.com/us/app/sogood-live-video-shopping/id1609670141"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;
        </a>
      </div>
      <Spacer flex={1} />
    </VStack>
  );
};

export default DownloadApp;
