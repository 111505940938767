const colors = require("./colors");

export default {
  colors: {
    text: {
      main: colors.grey_dark,
      dark: colors.black,
      light: colors.grey_light,
      ultralight: colors.grey_ultralight,
      white: colors.white,
    },
    bg: {
      common: colors.white_alt,
      dark: colors.grey_dark,
    },
    utility: {
      error: colors.red,
    },
    highlight: {
      primary: colors.purple,
      secondary: colors.turquoise,
    },
  },
  fontSizes: {
    heading: {
      lg: "24px",
      xl: "36px",
      "2xl": "48px",
      mega: "72px",
    },
    para: {
      main: "16px",
      small: "13px",
      tiny: "11px",
    },
  },
  space: {
    one: 1,
    xs: 4,
    sm: 8,
    md: 16,
    lg: 24,
    xl: 48,
    "2xl": 96,
  },
  radii: {
    sm: "3px",
    md: "6px",
    lg: "12px",
    xl: "24px",
    full: "9999px",
  },
  shadows: {
    sm: "0 1px 1px rgb(0 0 0 / 40%)",
    md: "0 2px 3px rgb(0 0 0 / 40%)",
  },
  zIndices: { header: "40", modal: "50", dropdown: "60" },
};
