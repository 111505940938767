import { VStack, FormControl, Text, Spacer, Input, HStack, Select, Button } from "@chakra-ui/react";
import React from "react";
import usStates from "../../../utils/us_states";

type ShippingFormProps = {
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  addressLine1: string;
  setAddressLine1: React.Dispatch<React.SetStateAction<string>>;
  addressLine2: string;
  setAddressLine2: React.Dispatch<React.SetStateAction<string>>;
  city: string;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  state: string;
  setUSState: React.Dispatch<React.SetStateAction<string>>;
  zipCode: string;
  setZipCode: React.Dispatch<React.SetStateAction<string>>;
  email: string | undefined;
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
  error: string | undefined;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
  handleSubmitAddress: () => void;
};

export function ShippingForm(props: ShippingFormProps) {


  return (
    <VStack
      padding="3"
      bg="gray.50"
      rounded="md"
      justifyContent={"space-between"}
    >
      <Text align="left" fontSize={"sm"}>
        Add Shipping Address
      </Text>
      <FormControl isRequired>
        <Spacer height={"2"} />
        <Input
          id="firstName"
          type="text"
          placeholder="Full Name"
          value={props.firstName}
          onChange={(e) => props.setFirstName(e.target.value)}
        />
        <Spacer height={"2"} />
        <Input
          id="addressLine1"
          type="text"
          placeholder="Address Line 1"
          value={props.addressLine1}
          onChange={(e) => props.setAddressLine1(e.target.value)}
        />
        <Spacer height={"2"} />
        <Input
          id="addressLine2"
          type="text"
          placeholder="Address Line 2"
          value={props.addressLine2}
          onChange={(e) => props.setAddressLine2(e.target.value)}
        />
        <Spacer height={"2"} />
        <HStack>
          <Input
            id="city"
            type="text"
            placeholder="City"
            value={props.city}
            onChange={(e) => props.setCity(e.target.value)}
          />
          <Select id="state" placeholder="CA" onChange={(e) => props.setUSState(e.target.value)}>
            {usStates.map((state) => (
              <option>{state.abbreviation}</option>
            ))}
          </Select>
          <Input
            id="zipCode"
            type="number"
            placeholder="Zip Code"
            value={props.zipCode}
            onChange={(e) => props.setZipCode(e.target.value)}
          />
        </HStack>

        <Spacer height={"2"} />
        <Input
          id="email"
          type="email"
          placeholder="Email Address"
          value={props.email}
          onChange={(e) => props.setEmail(e.target.value)}
        />
        <Spacer height={"3"} />
        <Text color={"red.500"}>{props.error}</Text>
        <Spacer height={"3"} />
        <Button
          colorScheme="purple"
          color="white"
          onClick={props.handleSubmitAddress}
        >
          Continue
        </Button>
      </FormControl>
    </VStack>
  );
}
  