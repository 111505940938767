import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import firebase from "firebase/app";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

const firebaseConfig = {
  apiKey: "AIzaSyAoPu8CPLcsIiBeMhjahZcmuxEXytxohU0",
  authDomain: "sogood-live-backend.firebaseapp.com",
  databaseURL: "https://sogood-live-backend-default-rtdb.firebaseio.com",
  projectId: "sogood-live-backend",
  storageBucket: "sogood-live-backend.appspot.com",
  messagingSenderId: "992023631103",
  appId: "1:992023631103:web:9dbdd1c886094c038fa8ca",
  measurementId: "G-30YQ6Q6GCG",
};

const theme = extendTheme({
  fonts: {
    heading: "Nunito",
    body: "Nunito",
  },
  components : {
    Button : {
      baseStyle: {
        _focus: {boxShadow: "none"},
      },
    },
  },
  
});

// Initialize Firebase
export const firebaseApp = firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
