/* eslint-disable @typescript-eslint/ban-types */
import { get, post, unauthedGet } from ".";
import { IOrder, IPurchaseDetails } from "../types/IPurchase";
import {
  failureServiceResponse,
  IServiceResponse,
  isServiceResponse,
} from "../types/IServiceResponse";

export const StripeGateway = {
  init: async (): Promise<IServiceResponse<string>> => {
    try {
      return await unauthedGet<IServiceResponse<string>>("/stripe/pk");
    } catch (err) {
      return failureServiceResponse("Could not get stripe key");
    }
  },

  checkout: async (
    purchaseDetails: IPurchaseDetails,
    email: string | undefined
  ): Promise<
    IServiceResponse<{
      paymentIntent: string | null;
      paymentIntentId: string;
      ephemeralKey: any;
      customer: any;
    }>
  > => {
    try {
      console.log("ASSSS");
      const customerId = await localStorage.getItem("stripeCustomerId");

      const stripeResponse = await post<
        IServiceResponse<{
          paymentIntent: any;
          paymentIntentId: string;
          ephemeralKey: any;
          customer: any;
        }>
      >("/stripe/payment-sheet", {
        purchase: purchaseDetails,
        email : email,
        customerId,
      });

      if (stripeResponse.success && stripeResponse.payload) {
        await localStorage.setItem(
          "stripeCustomerId",
          stripeResponse.payload.customer
        );
      }

      return stripeResponse;
    } catch (err) {
      return failureServiceResponse("Could not complete Stripe Checkout");
    }
  },

  submitPurchase: async (order: IOrder): Promise<IServiceResponse<{}>> => {
    try {
      return await post<IServiceResponse<{}>>("/purchase/submit", { order });
    } catch (err) {
      return failureServiceResponse("Could not submit purchase properly");
    }
  },
};
