
import { productTag } from "../types";
import {
  IServiceResponse,
  ITokenPaginationData,
} from "../types/IServiceResponse";

// export const getTimeRepresentation = (timeStamp: any) => {
//   const today = Moment();
//   const m = Moment(timeStamp);
//   let postfix = "";
//   if (m.startOf("day").format() === today.startOf("day").format()) {
//     postfix = "Today";
//   } else if (
//     m.startOf("day").format() === today.add(1, "day").startOf("day").format()
//   ) {
//     postfix = "Tomorrow";
//   } else if (m.startOf("week").format() === today.startOf("week").format()) {
//     postfix = Moment(timeStamp).format("ddd");
//   } else {
//     postfix = Moment(timeStamp).format("MMM DD");
//   }

//   return Moment(timeStamp).format("h:mm a") + " " + postfix;
// };

// export const getVerboseTimeRepresentation = (timeStamp: any) => {
//   const today = Moment();
//   const m = Moment(timeStamp);
//   let postfix = "";
//   if (m.startOf("day").format() === today.startOf("day").format()) {
//     postfix = "Today";
//   } else if (
//     m.startOf("day").format() === today.add(1, "day").startOf("day").format()
//   ) {
//     postfix = "Tomorrow";
//   } else if (m.startOf("week").format() === today.startOf("week").format()) {
//     postfix = Moment(timeStamp).format("ddd");
//   } else {
//     postfix = Moment(timeStamp).format("MMM DD");
//   }

//   return "" + postfix + " @ " + Moment(timeStamp).format("h:mm a");
// };

export const getProductTagAbbrv = (tag: productTag) => {
  switch (tag) {
    case "vegan":
      return "VE";
    case "gluten-free":
      return "GF";
    case "keto":
      return "K";
    case "whole-grain":
      return "WG";
    case "dairy-free":
      return "DF";
    case "vegetarian":
      return "V";
  }
};

export function validateServiceResponse<T>(
  response: IServiceResponse<T>
): T | undefined {
  if (response.success && response.payload) {
    return response.payload;
  } else {
    return undefined;
  }
}

export function validateArrayServiceResponse<T>(
  response: IServiceResponse<T[]>
): T[] {
  if (response.success && response.payload) {
    return response.payload;
  } else {
    return [];
  }
}

export function validatePaginatedArrayServiceResponse<T>(
  response: IServiceResponse<T[]>
): { payload: T[]; paging: ITokenPaginationData } {
  if (response.success && response.paging && response.payload) {
    const { payload, paging } = response;
    return { paging, payload };
  } else {
    return {
      paging: { type: "token", next: undefined, prev: undefined },
      payload: [],
    };
  }
}

export function flattenPaginatedQueryData<T>(
  pages: {
    payload: T[];
    paging: ITokenPaginationData;
  }[]
): T[] {
  return pages.map((p) => p.payload).flat();
}
