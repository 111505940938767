import React from "react";
import { StreamContentContainer } from "../../../styles";
import Button from "../../atoms/Patterns/Button";


export function Placeholder() {
  return <StreamContentContainer>
    <img src={require("../../../assets/images/image.png")} alt="sogood" style={{
      width: "100%",
      height: "100%",
      position: "absolute",
    }} />
    <div style={{
      color: "white",
      fontSize: 20,
      marginBottom: 30,
      position: "absolute",
      top: "60%",
      display: "flex",
      flexDirection: "column",
      height: "20%",
      justifyContent: "space-between",
      width: "80%",
      textAlign: "center",
    }}>
                No Stream Found
      {/* <div style={{
        display: "flex",
        flexDirection: "column",
      }}>
        <a style={{
          color: "white",
          textDecoration: "none",
          marginBottom: 10,
        }} href="https://testflight.apple.com/join/SmMau3VA">
          <Button>Get the App! </Button>
        </a>
        <a style={{
          color: "white",
          textDecoration: "none",
        }} href="https://shopsogood.live">
          <Button>Visit our Landing Page!</Button>
        </a>
      </div> */}
    </div>
  </StreamContentContainer>;
}
  