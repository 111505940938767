
import { useEffect, useState } from "react";
import { post } from "../api";
import { IServiceResponse } from "../types/IServiceResponse";


export type IInstagramService = {

  signIn: (username: string, password: string, shopId: string) => Promise<void>;
  user: any;
  logout: () => Promise<void>;
  error: string;
  twoFactor: any;
  verifyTwoFactor: (code: string, shopId: string) => Promise<void>

};

export const useInstagram = (): IInstagramService => {
  const [user, setUser] = useState<any>();
  const [twoFactor, setTwoFactor] = useState<any>();
  const [error, setError] = useState("");
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);


  async function signIn(username: string, password: string, shopId: string) {
    setError("");
    console.log("signing in");
    try {
      console.log(token);
      // eslint-disable-next-line @typescript-eslint/ban-types
      const res = await post<IServiceResponse<any>>(
        "/ig/login",
        {
          username,
          password,
          shopId,
        }
      );
      if(res.success && res.payload) {
        if(res.payload.two_factor) {
          setTwoFactor(res.payload.two_factor);
        } else if(res.payload.user) {
          setUser(res.payload.user);
        }
      } else {
        setError(res.message);
      }
      // const res = await Promise.resolve(successfulServiceResponse({}));
    } catch (err: any) {
      setError(err.response.data.message ?? "Unable to sign into Instagram");
    }
  }

  async function verifyTwoFactor(code: string, shopId: string) {
    setError("");
    if(!twoFactor) {
      setError("Don't need two factor?");
      return;
    }
    try {
      console.log(token);
      // eslint-disable-next-line @typescript-eslint/ban-types
      const res = await post<IServiceResponse<any>>("/ig/verify", {
        code, 
        // eslint-disable-next-line camelcase
        two_factor: twoFactor,
        shopId,
      });
      if(res.payload && res.success) {
        if(res.payload.user) {
          setUser(res.payload.user.logged_in_user);
        }
      }

    } catch (err: any) {
      setError(err.response.data.message ?? "Unable to sign into Instagram");
    }

  }



  async function logout() {
    setUser(null);
  }

  return {
    twoFactor,
    verifyTwoFactor,
    signIn,
    user,
    logout, 
    error,
  };
};
