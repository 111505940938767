/* eslint-disable camelcase */
import { Box } from "@chakra-ui/react";
import { BottomSheet } from "react-spring-bottom-sheet";
import { IProduct } from "../../../types";
import { SheetContent } from "../../molecules/SheetContent";
import "react-spring-bottom-sheet/dist/style.css";
import { useState, useEffect, useRef } from "react";
import { IPurchaseDetails } from "../../../types/IPurchase";
import { StripeGateway } from "../../../api/StripeGateway";
import { validateServiceResponse } from "../../../utils";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ProductModalHeader from "./ProductModalHeader";
import ProductPreview from "./ProductPreview";
import ProductOrderSummary from "./ProductOrderSummary";
import { isMobile } from "react-device-detect";



type ProductModalProps = {
  product: IProduct;
  isOpen: boolean; 
  handleClose : (justCheckedOut?: boolean) => void
}

const ProductModal = (props: ProductModalProps) => {


  const carouselRef = useRef<Carousel>(null);
  const [refresh, setRefresh] = useState(false);
  
  const [firstName, setFirstName] = useState<string>("");

  const [addressLine1, setAddressLine1] = useState<string>("");

  const [addressLine2, setAddressLine2] = useState<string>("");

  const [city, setCity] = useState<string>("");

  const [state, setUSState] = useState<string>("CA");

  const [zipCode, setZipCode] = useState<string>("");

  const [email, setEmail] = useState<string | undefined>();

  const [error, setError] = useState<string>();

  const [clientSecret, setClientSecret] = useState<string>();
  const [quantity, setQuantity] = useState(1);
  const [stripePurchaseId, setStripePurchaseId] = useState<string>();
  const [purchase, setPurchase] = useState<IPurchaseDetails>({
    productId: props.product.id,
    name: props.product.name,
    shopId: props.product.shopId,
    quantity: 1,
    timestamp: new Date(),
    price: props.product.discount,
    subtotal: 1 * props.product.discount,
    shipping: 0,
    total: 1 * props.product.discount,
  });

  console.log("SHIPPING", purchase.shipping);
  console.log("CLIENT SECRET" , clientSecret);

  useEffect(() => {
    setPurchase({
      productId: props.product.id,
      name: props.product.name,
      shopId: props.product.shopId,
      quantity: quantity,
      timestamp: new Date(),
      price: props.product.discount,
      subtotal: quantity * props.product.discount,
      shipping: 0,
      total: quantity * props.product.discount,
      shippingDetails: (firstName && email && addressLine1 && city && state && zipCode)
        ? {
          name: firstName,
          email,
          phone: "1234567890",
          address: {
            line1: addressLine1,
            line2: addressLine2,
            city,
            state,
            postal_code: zipCode,
            country: "US",
          },
        }
        : undefined,
    });

  }, [quantity, props.product, firstName, email, addressLine1, addressLine2, city, state, zipCode]);

  // carouselRef.current.on;

  useEffect(() => {
 
    setClientSecret(undefined);
    purchase && purchase.shippingDetails && StripeGateway.checkout(purchase, email)
    .then((stripeResponse) => { 
      const paymentIntent = validateServiceResponse(stripeResponse); 
      if(paymentIntent && paymentIntent.paymentIntent) {
        setStripePurchaseId(paymentIntent?.paymentIntentId);
        return paymentIntent.paymentIntent;
      } else {
        throw new Error("Could not validate purchase");
      }
      
    }).then((clientSecret) => {
      setClientSecret(clientSecret);
    });
  }, [props.isOpen, purchase]); 

  const snapToNext = () => {
    carouselRef.current?.increment();
  };

  const snapToPrev = () => {
    carouselRef.current?.decrement();
  };

  const[previewVisible, setPreviewVisible] = useState(true);

  const incrementQuantity = () => { 
    setPreviewVisible(false);
    setQuantity((q) => q+1); 
    setPreviewVisible(true);
  };

  const decrementQuantity = () => setQuantity((q) => q > 1 ? q - 1 : q);

  const handlePaymentComplete = () => {
    props.handleClose(true);
  };

  return (
    <BottomSheet
      open={props.isOpen}
      onDismiss={props.handleClose}
      expandOnContentDrag
      // defaultSnap={({ snapPoints, lastSnap }) =>
      //   lastSnap ?? Math.min(...snapPoints)
      // }
      // snapPoints={({ maxHeight }) => [
      //   maxHeight - maxHeight / 5,
      //   maxHeight * 0.6,
      // ]}
    >
      <Carousel
        ref={carouselRef}
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        swipeable={false}
      >
        <SheetContent height={isMobile ? "88vh" : "100%"}>
          <Box px={5} py={5}>
            {previewVisible && <ProductPreview
              product={props.product}
              purchase={purchase}
              decrementQuantity={decrementQuantity}
              quantity={quantity}
              incrementQuantity={incrementQuantity}
              onSubmitApplePay={handlePaymentComplete}
              snapToNext={snapToNext}
            />}
          </Box>
        </SheetContent>
        <SheetContent height={isMobile ? "100%" : "100%"}>
          <Box px={5} py={5} width="100%">
            <ProductModalHeader goBack={snapToPrev} title="Order" />
            <ProductOrderSummary
              product={props.product}
              quantity={quantity}
              purchase={purchase}
              stripePurchaseId={stripePurchaseId}
              snapToPrev={snapToPrev}
              snapToNext={snapToNext}
              onPaymentComplete={handlePaymentComplete}
              clientSecret={clientSecret}
              refresh={refresh}
              setRefresh={setRefresh}
              firstName={firstName}
              setFirstName={setFirstName}
              addressLine1={addressLine1}
              setAddressLine1={setAddressLine1}
              addressLine2={addressLine2}
              setAddressLine2={setAddressLine2}
              city={city}
              setCity={setCity}
              state={state}
              setUSState={setUSState}
              zipCode={zipCode}
              setZipCode={setZipCode}
              email={email}
              setEmail={setEmail}
              error={error}
              setError={setError}
            />
          </Box>
        </SheetContent>
      </Carousel>
    </BottomSheet>
  );
};

export default ProductModal;